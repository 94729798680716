/**
 * HOC handle `failure` prop by wrapping page components.
 */
import * as React from 'react';
import { useState } from 'react';
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { GetServerSideProps, GetServerSidePropsContext, InferGetServerSidePropsType } from 'next';

import { PageProps } from '@/types/_app';

export const withExceptionCatcher = (WrappedComponent: InferGetServerSidePropsType<GetServerSideProps<any>>) => {
    // eslint-disable-next-line
    return (props: PageProps) => {
        const [show, setShowException] = useState(true);

        // render normal page component if there is no error or error was dismissed
        if (!props.failure || !show) {
            return <WrappedComponent {...props} />;
        }

        const refreshPage = () => {
            window.location.reload();
        };

        const dismiss = () => {
            setShowException(false);
        };

        return (
            <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
                <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                    <div className="p-2 rounded-lg bg-red-600 shadow-lg sm:p-3">
                        <div className="flex items-center justify-between flex-wrap">
                            <div className="w-0 flex-1 flex items-center">
                                <span className="flex p-2 rounded-lg bg-red-800">
                                    <InformationCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                </span>
                                <p className="ml-3 font-medium text-white truncate">
                                    <span className="md:hidden">Error:</span>
                                    <span className="hidden md:inline">{props.failure.message}</span>
                                    {props.failure.code > 0 && (
                                        <span className="hidden md:inline">({props.failure.code})</span>
                                    )}
                                </p>
                            </div>
                            <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                                <a
                                    onClick={() => refreshPage()}
                                    href="#"
                                    className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-red-600 bg-white hover:bg-red-50"
                                >
                                    Refresh Page
                                </a>
                            </div>
                            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                                <button
                                    onClick={() => dismiss()}
                                    type="button"
                                    className="-mr-1 flex p-2 rounded-md hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-white"
                                >
                                    <span className="sr-only">Dismiss</span>
                                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

/**
 * Handle exceptions in original page components from getServerSideProps
 * `failure` prop will be passed if error occurs.
 */
export const getServerSidePropsWithCatcher =
    (getServerSidePropsFunc: Function) => async (ctx: GetServerSidePropsContext) => {
        try {
            return getServerSidePropsFunc(ctx);
        } catch (error: any) {
            return {
                props: {
                    failure: {
                        message: error.message || 'Unknown Error',
                        code: 0,
                    },
                },
            };
        }
    };

import * as React from 'react';

import { DateFormat } from '@blockworks/ui/date-time';
import { Link } from '@blockworks/ui/legacy/link';
import { cn } from '@blockworks/ui/utils';

import { Author } from '@/types/article-type';

type ByLineProps = {
    authors: Author[];
    publishedAt: string;
    showTime?: boolean;
    className?: string;
    prefetchAuthor?: boolean;
};

const ByLine = (props: ByLineProps) => {
    const { publishedAt, authors, className, showTime, prefetchAuthor = false } = props;

    return (
        <div className={cn('flex flex-wrap justify-start items-start gap-1', className)}>
            {authors.length > 0 && (
                <div className="flex flex-wrap gap-1 uppercase">
                    by{' '}
                    {authors.map((author, index) => (
                        <span key={author.id}>
                            {author.id ? (
                                <Link href={`/author/${author.slug}`} prefetch={prefetchAuthor} className="link-gray">
                                    {author.title}
                                </Link>
                            ) : (
                                <span className="link-gray">{author.title}</span>
                            )}
                            {index !== authors.length - 1 && <span className="ml-1">&</span>}
                        </span>
                    ))}
                    <span> /</span>
                </div>
            )}
            <div className="flex justify-start items-start relative gap-1 uppercase">
                <DateFormat date={publishedAt} format={showTime ? 'LLLL d, yyyy hh:mm aaa' : 'LLLL d, yyyy'} />
            </div>
        </div>
    );
};

export default ByLine;

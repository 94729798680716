import * as React from 'react';
import { useEffect, useState } from 'react';

import { Spinner } from '@blockworks/ui/spinner';
import { cn } from '@blockworks/ui/utils';

import NewsCategoryTabs, { NewsCategoryTab } from '@/components/pages/frontpage/components/news/news-category-tabs';
import NewsSectionArticle from '@/components/pages/frontpage/components/news/news-section-article';
import type { ArticleType } from '@/types/article-type';
import mapAlgoliaHits from '@/utils/functions/algolia/map-algolia-hits';
import queryAlgoliaPosts from '@/utils/functions/algolia/query-algolia-posts';

type NewsSectionProps = {
    className?: string;
    excludePostIds?: number[];
    articles: ArticleType[];
    categoryTabs: NewsCategoryTab[];
    onCategoryChange?: (category: NewsCategoryTab) => void;
};

const NewsSection = (props: NewsSectionProps) => {
    const { className, categoryTabs, onCategoryChange } = props;
    const [selectedCategorySlug, setSelectedCategorySlug] = useState<string | null>(null);
    const [articles, setArticles] = useState<ArticleType[]>(props.articles.slice(5, 11));
    const [isLoadingArticles, setIsLoadingArticles] = useState(false);

    useEffect(() => {
        if (selectedCategorySlug === null) {
            return;
        }

        setIsLoadingArticles(true);
        queryAlgoliaPosts('', 6, 1, {
            filters: `taxonomies.category:${selectedCategorySlug}`,
        }).then(posts => {
            setArticles(
                mapAlgoliaHits(posts.hits).filter(
                    article => !(props.excludePostIds || []).includes(Number(article.id)),
                ),
            );
            setIsLoadingArticles(false);
        });
    }, [selectedCategorySlug]);

    return (
        <div
            className={cn(
                'flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-5 px-10 pb-5 w-full',
                className,
            )}
        >
            <div>
                <NewsCategoryTabs
                    tabs={categoryTabs}
                    onClickTab={tab => {
                        setSelectedCategorySlug(tab.slug);
                        onCategoryChange && onCategoryChange(tab);
                    }}
                />
            </div>
            {!isLoadingArticles && (
                <div className="mt-0 md:mt-6 lg:mt-12 grid gap-8 grid-cols-1 xs:grid-cols-2 md:grid-cols-3 xl:grid-cols-6">
                    {articles.map(article => (
                        <NewsSectionArticle article={article} key={`NewsSectionArticle-${article.slug}`} />
                    ))}
                </div>
            )}

            {isLoadingArticles && (
                <div className="mt-12 h-80 w-full">
                    <div className="mx-auto">
                        <Spinner />
                    </div>
                </div>
            )}
        </div>
    );
};

export default NewsSection;

import EditorialConfig from '@/editorial-config';
import { AlgoliaPostHit, AlgoliaPostHitTaxonomies } from '@/types/algolia/algolia-post-hit';
import type { ArticleType } from '@/types/article-type';
import decodeEntities from '@/utils/functions/string/decode-entities';
import stripHtmlTags from '@/utils/functions/string/strip-html-tags';

const mapAlgoliaHits = (hits: AlgoliaPostHit[]): ArticleType[] => {
    return hits?.map(hit => {
        const taxonomies: AlgoliaPostHitTaxonomies | null = hit.taxonomies || null;
        const slug =
            hit.slug ||
            hit.permalink
                .replace('https://blockworks.co', '')
                .replace('https://cms.blockworks.co', '')
                .replace('/news/', '')
                .replace('/', '');

        const imageUrl =
            hit.images.thumbnail?.url
                .replace(`-${hit.images.thumbnail.width}x${hit.images.thumbnail.height}-1`, '')
                .replace(`-${hit.images.thumbnail.width}x${hit.images.thumbnail.height}`, '')
                .replace('https://blockworks.co', 'https://cms.blockworks.co')
                ?.split('?')[0] ?? '';

        return {
            id: `${hit.post_id}`,
            permalink: `${EditorialConfig.SITE_URL}/news/${slug}`,
            slug,
            title: decodeEntities(hit.post_title),
            type: hit.post_type,
            typeLabel: hit.post_type_label,
            isSticky: !!hit.is_sticky,
            imageUrl,
            thumbnail: {
                url: imageUrl,
                dimensions: {
                    width: hit.images.thumbnail?.width ?? 0,
                    height: hit.images.thumbnail?.height ?? 0,
                },
            },
            blurhash: null,
            lastUpdate: new Date(hit.post_modified).toISOString(),
            publishedAt: hit.post_date_formatted,
            publishedAtTimestamp: hit.post_date,
            excerpt: decodeEntities(stripHtmlTags(hit.post_excerpt)),
            content: '',
            primaryCategory: taxonomies && taxonomies.category?.length > 0 ? hit.taxonomies.category[0]! : '',
            categories: hit.taxonomies.category,
            primaryTag: taxonomies && taxonomies.post_tag?.length > 0 ? hit.taxonomies.post_tag[0]! : '',
            tags: hit.taxonomies.post_tag || [],
            authors: [
                {
                    title: hit.post_author.display_name,
                    id: hit.post_author.user_id,
                    slug: hit.post_author.slug,
                },
            ],
        };
    });
};

export default mapAlgoliaHits;

import * as React from 'react';

import { Link } from '@blockworks/ui/legacy/link';
import { cn } from '@blockworks/ui/utils';

type SectionHeaderProps = {
    className?: string;
    title: string;
    description?: string;
    linkTitle?: React.ReactNode | JSX.Element | string;
    linkUrl?: string;
    textNormal?: boolean;
    variant?: 'grid' | 'plain';
};

const variants = {
    grid: 'px-10 py-5',
    plain: '',
};

const SectionHeader = (props: SectionHeaderProps) => {
    const { title, description, linkTitle, linkUrl, className, textNormal, variant = 'grid' } = props;

    return (
        <div className={cn('md:mt-0 md:flex md:justify-between md:items-center w-full', variants[variant], className)}>
            <div className="flex justify-start items-center gap-3">
                <div className="relative">
                    <h2 className={cn('text-base uppercase text-dark', textNormal ? '' : 'font-semibold')}>
                        {linkUrl ? (
                            <Link
                                href={linkUrl}
                                prefetch={false}
                                rel={linkUrl.indexOf('https://') !== -1 ? 'nofollow' : undefined}
                            >
                                <>{title}</>
                            </Link>
                        ) : (
                            <>{title}</>
                        )}
                    </h2>
                </div>
                {description && (
                    <div className="hidden md:flex justify-start items-start text-center md:text-left relative gap-2.5">
                        <p className="text-light-gray">{description}</p>
                    </div>
                )}
            </div>
            <div>
                {linkUrl && (
                    <Link
                        href={linkUrl}
                        prefetch={false}
                        rel={linkUrl.indexOf('https://') !== -1 ? 'nofollow' : undefined}
                        className="text-sm text-center md:text-left uppercase text-primary"
                    >
                        <>{linkTitle}</>
                    </Link>
                )}
            </div>
        </div>
    );
};

export default SectionHeader;

import type { SearchOptions, SearchResponse } from '@algolia/client-search';
import type { SearchIndex } from 'algoliasearch/dist/algoliasearch-lite';

const queryAlgoliaIndex = <AlgoliaResponse>(
    index: SearchIndex,
    query: string,
    hitsPerPage: number = 10,
    page: number = 0,
    requestOptions: SearchOptions = {},
): Readonly<Promise<SearchResponse<AlgoliaResponse>>> => {
    return index?.search(query, {
        analytics: true,
        clickAnalytics: true,
        attributesToRetrieve: ['*'],
        attributesToSnippet: ['*:20'],
        enableABTest: false,
        explain: ['*'],
        facets: ['*'],
        getRankingInfo: true,
        hitsPerPage,
        page,
        maxValuesPerFacet: 100,
        responseFields: ['*'],
        snippetEllipsisText: '…',
        ...requestOptions,
    });
};

export default queryAlgoliaIndex;

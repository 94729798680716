import { formatInTimeZone } from '@blockworks/platform/libs/date-fns';
import { toUtcDate } from '@blockworks/platform/libs/moment';

const NY_TIMEZONE = 'America/New_York';

type DateFormatProps = {
    className?: string;
    date: string | number | null | Date;
    format?: string;
};

const parseTimeStringToDate = (dateStringOrTimestamp: string | number | Date): Date => {
    // eslint-disable-next-line no-restricted-globals
    if (typeof dateStringOrTimestamp === 'string') {
        return toUtcDate(dateStringOrTimestamp);
    }
    const dateNum =
        Number(dateStringOrTimestamp) > 9999999999
            ? Number(dateStringOrTimestamp)
            : Number(dateStringOrTimestamp) * 1000;
    return toUtcDate(dateNum);
};

const DateFormat = ({ date: dateProp, className, format: dateFormat }: DateFormatProps) => {
    if (!dateProp) {
        return null;
    }

    try {
        const date = parseTimeStringToDate(dateProp);
        return (
            <time suppressHydrationWarning dateTime={date.toISOString()} className={className}>
                {formatInTimeZone(date, NY_TIMEZONE, dateFormat || 'LLLL d, yyyy')}
            </time>
        );
    } catch (error) {
        console.error(error);
        return null;
    }
};

export type { DateFormatProps };
export { DateFormat };

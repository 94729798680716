import * as React from 'react';

import { slugify } from '@blockworks/platform/utils';
import { Link } from '@blockworks/ui/legacy/link';
import { cn } from '@blockworks/ui/utils';

import { ImageWithFallback } from '@/components/common';
import ByLine from '@/components/pages/frontpage/components/feature/middle/by-line';
import type { ArticleType } from '@/types/article-type';
import decodeEntities from '@/utils/functions/string/decode-entities';

type NewsSectionProps = {
    className?: string;
    article: ArticleType;
    imageQuality?: number;
    onClick?: () => void;
};

const NewsSection = (props: NewsSectionProps) => {
    const { className, article, onClick, imageQuality } = props;

    return (
        <div className={cn('flex flex-col justify-start self-stretch flex-grow gap-2 w-full', className)}>
            <Link href={`/news/${article.slug}`} className="cursor-pointer" onClick={() => onClick && onClick()}>
                <ImageWithFallback
                    src={article.imageUrl}
                    fallbackSrc="/images/Blockworks_Meta_Logo.png"
                    width={768}
                    height={432}
                    decoding="async"
                    className="w-full h-auto"
                    quality={imageQuality ?? 40}
                    alt="article-image"
                />
            </Link>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-1">
                <p className="flex whitespace-nowrap overflow-auto scrollbar-hide flex-grow text-[10px] text-left uppercase text-primary gap-2">
                    {article.categories.slice(0, 2).map(category =>
                        category.toLowerCase() === 'sponsored' ? (
                            <span key={category} className="text-gray-700">
                                {category}
                            </span>
                        ) : (
                            <Link key={category} href={`/category/${slugify(decodeEntities(category))}`}>
                                {decodeEntities(category)}
                            </Link>
                        ),
                    )}
                </p>
            </div>
            <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-2.5">
                <Link
                    href={`/news/${article.slug}`}
                    className="font-headline flex-grow text-base font-semibold leading-snug hover:text-primary"
                    onClick={() => onClick && onClick()}
                >
                    {article.title}
                </Link>
            </div>
            <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-2.5">
                <p className="flex-grow text-xs text-left text-light-gray text-light-gray">{article.excerpt}</p>
            </div>
            <ByLine className="text-xxs" authors={article.authors} publishedAt={article.publishedAt} />
        </div>
    );
};

export default NewsSection;

import { default as moment, tz } from 'moment-timezone';

const toUtcIsoString = (value?: string | number | null | Date) => {
    return moment(value).utc().toISOString();
};

const toUtcDate = (value?: string | number | null | Date) => {
    return moment(value).utc().toDate();
};

const toUTCStringWithTimeZone = (date: string, format: string, timezone: string) => {
    return moment.tz(date, format, timezone).utc().toISOString();
};

/**
 * Returns the utc offset given the current timzone. If a zone is not provided,
 * it will default to the system's resolved option e.g. `Intl.DateTimeFormat().resolvedOptions().timeZone`
 */
const getUtcOffset = (timestamp: Date | string | number, zone = Intl.DateTimeFormat().resolvedOptions().timeZone) => {
    return -tz(timestamp, zone).utcOffset();
};

export { getUtcOffset, toUtcDate, toUtcIsoString, toUTCStringWithTimeZone };

import { SearchOptions, SearchResponse } from '@algolia/client-search';
import algoliasearch from 'algoliasearch/lite';

import EditorialConfig from '@/editorial-config';
import { AlgoliaPostHit } from '@/types/algolia/algolia-post-hit';
import queryAlgoliaIndex from '@/utils/functions/algolia/query-algolia-index';

const queryAlgoliaPosts = <T = Readonly<Promise<SearchResponse<AlgoliaPostHit>>>>(
    query: string,
    hitsPerPage: number = 10,
    page = 0,
    options: SearchOptions & { proxy?: boolean } = {},
): T => {
    // Use proxy to get cached results
    if (options?.proxy) {
        // TODO: ask @Mike how/if he wants to do this in @platform!
        const searchParams = new URLSearchParams();
        searchParams.set('searchTerm', query);
        searchParams.set('page', page.toString());
        searchParams.set('hitsPerPage', hitsPerPage.toString());
        searchParams.set('filters', options.filters ?? '');
        return fetch(`/api/algolia/search?${searchParams.toString()}`).then(res => res.json()) as T;
    }
    const client = algoliasearch(EditorialConfig.ALGOLIA_APP_ID, EditorialConfig.ALGOLIA_KEY);
    const index = client.initIndex('wp_posts_post');

    return queryAlgoliaIndex(index, query, hitsPerPage, page, options) as T;
};

export default queryAlgoliaPosts;
